/* eslint-disable @typescript-eslint/member-ordering */
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DecimalPipe } from '@angular/common';
import {
  Component,
  Inject,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@sonar/core';
import { dataServiceFactoryProvider } from '@sonar/core/data-service.factory';
import { AlertService } from '@sonar/core/data/alert.service';
import { FarmService } from '@sonar/core/data/farm.service';
import { HouseDataService } from '@sonar/core/data/house-data.service';
import { LaunchDarklyService } from '@sonar/core/launch-darkly.service';
import { MaxCacheAge } from '@sonar/core/max-cache-age';
import { UnitConversionService } from '@sonar/core/unit-conversion.service';
import { UserSetting } from '@sonar/core/user-settings/user-settings';
import { UserSettingsService } from '@sonar/core/user-settings/user-settings.service';
import { Alert } from '@sonar/shared/alerts/alert';
import { ReadAlert } from '@sonar/shared/alerts/read-alert';
import { AnalyticsFlockSelection } from '@sonar/shared/analytics/analytics-flock-selection';
import { FarmAnalytics } from '@sonar/shared/analytics/farm-analytics';
import { FarmAnalyticsComponent } from '@sonar/shared/analytics/farm-analytics.component';
import { DepletionDataEntrySource } from '@sonar/shared/depletion-data-entry-source';
import { Farm } from '@sonar/shared/farm/farm';
import { FeatureFlags } from '@sonar/shared/feature-flags';
import { FlockEvent } from '@sonar/shared/flock-event';
import { FlockEventType } from '@sonar/shared/flock-event-type';
import { IntegrationOption } from '@sonar/shared/integration-option';
import { DepletionModalComponent } from '@sonar/shared/modals/depletion/depletion-modal.component';
import { SensorType, SonarDataType } from '@sonar/shared/sensor-type';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject, lastValueFrom } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CalendarEventDialogComponent } from './calendar-event-dialog.component';
import { EventFilterModalComponent } from './event-filter-modal/event-filter-modal.component';
import { EventModalComponent } from './event-modal/event-modal.component';
import { EventTimelineComponent } from './event-timeline/event-timeline.component';
import { MtSwiperComponent } from '@sonar/shared/mt-swiper/mt-swiper.component';
import { SonarSpeciesType } from '@sonar/shared/sonar-species-type';
import { HimTransCode } from '@sonar/shared/him-trans-code';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-house-list',
  templateUrl: './house-list.page.html',
  styleUrls: ['./house-list.page.scss'],
  providers: [
    dataServiceFactoryProvider(
      { endPoint: 'Analytics', maxCacheAge: MaxCacheAge.Long },
      'farmAnalyticsDataService',
    ),
    dataServiceFactoryProvider(
      { endPoint: 'FlockComparisonAnalytics', maxCacheAge: MaxCacheAge.Medium },
      'flockComparisonDataService',
    ),
    dataServiceFactoryProvider(
      { endPoint: 'UpcomingEvents', maxCacheAge: MaxCacheAge.Medium },
      'upcomingEventsDataService',
    ),
    dataServiceFactoryProvider(
      { endPoint: 'ActualEvents', maxCacheAge: MaxCacheAge.Medium },
      'actualEventsDataService',
    ),
    dataServiceFactoryProvider(
      { endPoint: 'BinFeedAmounts', maxCacheAge: MaxCacheAge.Medium },
      'binFeedAmountsDataService',
    ),
    DecimalPipe,
  ],
  animations: [
    trigger('enterTrigger', [
      state(
        'fadeIn',
        style({
          opacity: '1',
        }),
      ),
      transition('void => *', [style({ opacity: '0' }), animate('.35s')]),
    ]),
  ],
})
export class HouseListPage {
  private loading: any;
  private readonly unsubscribe$ = new Subject<void>();

  @ViewChild(EventTimelineComponent) eventTimeline: EventTimelineComponent;
  @ViewChild(FarmAnalyticsComponent)
  farmAnalyticsComponent: FarmAnalyticsComponent;
  @ViewChild(MtSwiperComponent) private swiper: MtSwiperComponent;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly platform: Platform,
    private readonly storage: Storage,
    private readonly modalController: ModalController,
    private readonly alertController: AlertController,
    private readonly toastController: ToastController,
    private readonly alertService: AlertService,
    private readonly farmService: FarmService,
    private readonly houseDataService: HouseDataService,
    private readonly launchDarklyService: LaunchDarklyService,
    private readonly translateService: TranslateService,
    private readonly userSettingsService: UserSettingsService,
    private readonly loadingController: LoadingController,
    private readonly actionSheetController: ActionSheetController,
    private readonly dialog: MatDialog,
    public readonly unitConversionService: UnitConversionService,
    @Inject('farmAnalyticsDataService')
    private readonly farmAnalyticsDataService: DataService,
    @Inject('flockComparisonDataService')
    private readonly flockComparisonDataService: DataService,
    @Inject('actualEventsDataService')
    private readonly actualEventsDataService: DataService,
    @Inject('upcomingEventsDataService')
    private readonly upcomingEventsDataService: DataService,
    @Inject('binFeedAmountsDataService')
    private readonly binFeedAmountsDataService: DataService,
  ) {}

  public farmId: number;
  public farm: Farm;
  public houses: any[];
  alerts: Alert[];
  currentAlertFarmsHouse: any[] = [];
  currentFarmAlerts: Alert[] = [];
  events: FlockEvent[];
  eventTabs: 'upcoming' | 'past' = 'upcoming';
  farmAnalytics: FarmAnalytics;
  houseDetails: any[];
  housePenDetails: any[];
  flockStatisticsComparison: any;
  houseTabs: 'slide' | 'grid' = 'slide';
  isBusyFarmAnalytics = false;
  navTabs: 'houses' | 'events' | 'analytics' | 'alerts' = 'houses';
  analyticsTab: AnalyticsFlockSelection = AnalyticsFlockSelection.Current;
  pastEvents: FlockEvent[];
  showAminoMobileLink: boolean;
  showDepletionOption: boolean;
  showFlockComparisonInfo: boolean;
  userSetting: UserSetting;
  readonly sensorCount: number = 5;
  readonly defaultBackUrl = 'farms';
  menuOptions: any[];
  loaded: boolean;
  showHighcharts: boolean;
  loadingEvents: any;
  loadingAlerts: any;
  eventTypeEnum = FlockEventType;
  eventTypeName: string;
  upcomingEventsCustom: any[];
  pastEventsCustom: any[] = [];
  upcomingEventsByDate: any[];
  pastEventsByDate: any[];
  eventFilters: { filterBy: string; type: string }[] = [
    { filterBy: 'all', type: 'eventType' },
    { filterBy: 'all', type: 'status' },
  ];
  eventsReady: boolean;
  eventBatchNumber: number;
  hasMoreEvents: boolean;
  housesWithPens = false;
  houseClicked = 0;
  sensorTypeClicked: any;
  eventsEnabled = true;
  binsDataSource: any[];

  async ionViewWillEnter() {
    this.userSetting = await this.userSettingsService.getUserSettings();
    this.eventsEnabled =
      this.userSetting.sonarSpeciesType !== SonarSpeciesType.Swine;
    const loadingMessage = this.translateService.instant('LOADING_HOUSES');
    this.loading = await this.loadingController.create({
      message: loadingMessage,
    });
    await this.loading?.present();

    this.farmId = Number(this.route.snapshot.params.farmId);
    const tab = this.route.snapshot.paramMap.get('tab') as
      | 'houses'
      | 'events'
      | 'analytics'
      | 'alerts';
    if (tab) {
      this.navTabs = tab;
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { tab: this.navTabs },
      });
    }
    this.farm = await this.farmService.getFarm(this.farmId);

    this.alerts = await this.alertService.getAlerts();
    this.loadAlerts();
    this.eventFilters = this.userSetting.eventFilters;
    this.houseTabs = this.userSetting.houseView ?? 'slide';
    this.userSettingsService.$settings
      .pipe(filter((s) => !_.isEqual(this.userSetting, s)))
      .subscribe(async (s) => {
        this.userSetting = s;
        this.eventsEnabled =
          this.userSetting.sonarSpeciesType !== SonarSpeciesType.Swine;
        this.eventFilters = this.userSetting.eventFilters;
        this.houseTabs = this.userSetting.houseView;
      });
    this.eventBatchNumber = 0;
    await this.loadTabAsync();

    const userInfo = await this.userSettingsService.getUserInfo();

    this.showAminoMobileLink =
      userInfo.integrationOption === IntegrationOption.Amino &&
      !!this.farm &&
      !!this.farm.proteinFarmsIRN;

    const depletionDataEntrySource =
      this.farm.depletionDataEntrySource === DepletionDataEntrySource.NotSet
        ? userInfo.depletionDataEntrySource
        : this.farm.depletionDataEntrySource;
    this.showDepletionOption =
      depletionDataEntrySource === DepletionDataEntrySource.Sonar;

    await this.buildMenuOptions();

    this.binFeedAmountsDataService
      .getEntities<any>({ farmId: this.farmId }, 'BinFeedAmountsByFarm')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((s) => {
        this.binsDataSource = s.map((b) => ({
          ...b,
          sensorType: SensorType.BinFeedAmount,
          openBin: 'l ami-open',
        }));
      });
  }

  ionViewDidEnter() {
    this.alertService.alerts$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((alerts) => !!alerts),
      )
      .subscribe((alerts) => {
        this.alerts = alerts;
        this.loadAlerts();
      });
  }

  ionViewWillLeave() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    clearTimeout(this.loadingEvents);
  }

  async ionViewDidLeave() {
    if (this.houseTabs === 'slide' && this.swiper) {
      this.swiper.resetSwiper();
    }
    await this.loading?.dismiss();
  }

  async showFlockComparisionInformation() {
    const alert = await this.alertController.create({
      message: this.translateService.instant(
        'FARM_DETAIL_PAGE.ANALYTICS_SEGMENTS.FLOCKS_COMPARISON_INFO',
      ),
    });
    await alert.present();
  }

  async onHousesTabsChange(args: any) {
    this.houseTabs = args.detail.value;
    await this.userSettingsService.save('houseView', this.houseTabs);
  }

  async onNavTabsChange(args: any) {
    this.navTabs = args.detail.value;
    let queryParams: any = { tab: this.navTabs };
    this.showFlockComparisonInfo = false;
    if (this.navTabs === 'events') {
      queryParams = { tab: this.navTabs, eventTab: this.eventTabs };
    } else if (this.navTabs === 'analytics') {
      this.loaded = false;
      queryParams = {
        tab: this.navTabs,
        analyticsTab: _.camelCase(AnalyticsFlockSelection[this.analyticsTab]),
      };
    } else if (this.navTabs === 'alerts') {
      this.loaded = true;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
    });

    await this.loadTabAsync();
  }

  async onFlockSelectionChanged(args) {
    this.analyticsTab = args.value;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: this.navTabs,
        analyticsTab: _.camelCase(AnalyticsFlockSelection[this.analyticsTab]),
      },
    });
    this.isBusyFarmAnalytics = true;
    this.farmAnalytics = await lastValueFrom(
      this.farmAnalyticsDataService.getEntity<FarmAnalytics>({
        farmId: this.farmId,
        flockSelection: args.value as AnalyticsFlockSelection,
      }),
    );
    this.isBusyFarmAnalytics = false;
    this.showFlockComparisonInfo =
      (Number(args.value) as AnalyticsFlockSelection) ===
      AnalyticsFlockSelection.FlocksComparison;
  }

  refresh(event: any) {
    this.reload();
    event.target.complete();
  }

  async reload() {
    if (this.navTabs === 'events') {
      await this.loadEvents();
    } else if (this.navTabs === 'analytics') {
      this.farmAnalytics = await lastValueFrom(
        this.farmAnalyticsDataService.getEntity<FarmAnalytics>({
          farmId: this.farmId,
          flockSelection: this.analyticsTab,
        }),
      );
    } else {
      await this.alertService.refreshAlerts();
      await this.loadHouses();
    }
    setTimeout(() => (this.loaded = true));
  }

  async loadEvents() {
    let newEvents: FlockEvent[] = [];

    newEvents = await lastValueFrom(
      this.upcomingEventsDataService.getEntities<FlockEvent>({
        farmId: this.farmId,
      }),
    );

    newEvents.forEach((e) => {
      e.formattedTimestamp = moment(e.timestamp).format('lll');
      if (e.catchDate) {
        e.formattedCatchDate = moment(e.catchDate).format('lll');
      }
      if (e.arrivalTime) {
        e.formattedArrivalTime = moment(e.arrivalTime).format('lll');
      }
      if (e.dateFeedersRaised) {
        e.formattedDateFeedersRaised = moment(e.dateFeedersRaised).format(
          'lll',
        );
      }
      e.isUpcomingEvent = true;
      if (e.beginLoadTime) {
        e.formattedBeginLoadTime = moment(e.beginLoadTime).format('lll');
      }

      if (e.endLoadTime) {
        e.formattedEndLoadTime = moment(e.endLoadTime).format('lll');
      }

      e.eventName = FlockEventType.getEventName(e.eventType);
      e.eventColor = FlockEventType.toColor(e.eventType);
    });
    if (newEvents.length > 0) {
      this.events = newEvents.sort((a, b) => {
        if (a.timestamp < b.timestamp) {
          return -1;
        } else if (a.timestamp === b.timestamp) {
          if (a.refNo && b.refNo) {
            if (a.refNo < b.refNo) {
              return -1;
            } else if (a.refNo === b.refNo) {
              if (a.eventType < b.eventType) {
                return -1;
              } else if (a.eventType === b.eventType) {
                return 0;
              }
            }
          } else if (!!a.houseNo && !!b.houseNo) {
            if (a.houseNo < b.houseNo) {
              return -1;
            }

            return 0;
          }
        }
        return 1;
      });
    }
  }

  async loadPastEvents() {
    let newEvents: FlockEvent[] = [];

    newEvents = await lastValueFrom(
      this.actualEventsDataService.getEntities<FlockEvent>({
        farmId: this.farmId,
        batchNumber: this.eventBatchNumber,
      }),
    );
    this.hasMoreEvents = await lastValueFrom(
      this.actualEventsDataService.getValue<boolean>('HasMoreEvents', {
        farmId: this.farmId,
        batchNumber: this.eventBatchNumber,
      }),
    );

    newEvents.forEach((e) => {
      e.formattedTimestamp = moment(e.timestamp).format('lll');
      e.eventColor = FlockEventType.toColor(e.eventType);
      e.feedTransCodeLabel = FlockEventType.getFeedTransCodeLabel(e.eventType);
      e.himTransCodeLabel = HimTransCode.getHimTransCodeLabel(e.transCode);
      e.eventName = FlockEventType.getEventName(e.eventType);
    });
    if (newEvents.length > 0) {
      this.pastEvents = _.orderBy(
        newEvents,
        ['timestamp', 'refNo', 'eventType'],
        ['desc', 'asc', 'asc'],
      );
    }
  }

  async loadHouses() {
    const farmDetails = await this.houseDataService.getHouseDetails(
      this.farmId,
    );
    if (!farmDetails || farmDetails.length === 0) {
      return;
    }

    if (this.alerts) {
      this.groupHouseAlerts(farmDetails);
    }
    this.housePenDetails = farmDetails.filter(
      (detail) => detail.sensorCounts.length > 0,
    );
    this.housesWithPens =
      this.launchDarklyService.boolVariation(
        FeatureFlags.UseLimitedHouseCard,
      ) && this.housePenDetails.some((detail) => !_.isNil(detail.penId));

    this.houseDetails = this.housePenDetails.filter((detail) =>
      _.isNil(detail.penId),
    );
  }

  onSlideClicked(args: any) {
    const element = args.clickedElement as HTMLElement;
    if (!element) {
      return;
    }
    if (
      element.closest('.house-card__header') ||
      element.closest('.house-card__kpi')
    ) {
      this.openSensorsListPage(args.clickedIndex);
      return;
    }
    if (element.closest('.sensor__item')) {
      const sensorType = Number(element.closest('.sensor__item')?.id);
      this.openSensorDetail(args.clickedIndex, sensorType);
      return;
    }
  }

  openSensorsListPage(index: number) {
    const houseDetail = this.houseDetails[index];

    if (!houseDetail) {
      return;
    }
    this.houseClicked = index;
    this.storage.set('houseClicked', this.houseClicked);
    this.router.navigate([
      `/farms/${this.farmId.toString()}/houses/${houseDetail.houseId.toString()}`,
    ]);
  }

  openSensorDetail(index: number, sensorType: number) {
    const houseDetail = this.houseDetails[index];

    this.router.navigate([
      `/farms/${this.farmId.toString()}/houses/${houseDetail.houseId.toString()}/sensors/${SonarDataType.toString(
        sensorType,
      )}/overview`,
    ]);
  }

  async onAlertSelected(args) {
    const alert = args as Alert;
    if (!this.userSetting.readAlerts) {
      this.userSetting.readAlerts = [];
    }

    if (
      !this.userSetting.readAlerts.some((a) => this.filterReadAlerts(alert, a))
    ) {
      this.userSetting.readAlerts.push({
        farmId: alert.farmId,
        houseId: alert.houseId,
        sensorType: alert.sensorType,
        sensorIndex: alert.sensorIndex,
        start: alert.start,
      });

      await this.userSettingsService.save(
        'readAlerts',
        this.userSetting.readAlerts.filter(
          (r) =>
            this.currentFarmAlerts.some((a) => this.filterReadAlerts(a, r)) ||
            r.farmId !== this.farmId,
        ),
      );
    }
    this.router.navigate([`farms/${alert.farmId}/houses/${alert.id}/detail`]);
  }

  private async loadTabAsync() {
    switch (this.navTabs) {
      case 'events':
        this.loaded = false;
        await this.loadEvents();
        await this.loadPastEvents();
        this.eventsReady = true;
        this.loadingEvents = setTimeout(() => (this.loaded = true));
        break;
      case 'analytics':
        await this.loadAnalytics();
        break;
      case 'houses':
        //Log page change here instead of in loadHouses to prevent duplicates
        await this.loadHouses();
        break;
    }
    await this.loading?.dismiss();
  }

  private async loadAnalytics() {
    this.isBusyFarmAnalytics = true;
    this.farmAnalytics = await lastValueFrom(
      this.farmAnalyticsDataService.getEntity<FarmAnalytics>({
        farmId: this.farmId,
        flockSelection: AnalyticsFlockSelection.Current,
      }),
    );
    this.isBusyFarmAnalytics = false;
    this.flockStatisticsComparison = await lastValueFrom(
      this.flockComparisonDataService.getEntity<any>({
        proteinFarmsId: this.farmId,
      }),
    );
    delete this.events;
  }

  private loadAlerts() {
    this.currentFarmAlerts = this.alerts.filter(
      (a) => a.farmId === this.farmId,
    );

    if (this.houseDetails) {
      this.groupHouseAlerts(this.houseDetails);
    }
  }

  private groupHouseAlerts(farmDetails) {
    this.currentAlertFarmsHouse = farmDetails.map((house) =>
      this.alerts.filter((a) => !a.end && a.houseId === house.houseId)
        ? this.alerts.filter((a) => !a.end && a.houseId === house.houseId)
            .length
        : 0,
    );
  }

  private filterReadAlerts(alert: Alert, readAlert: ReadAlert) {
    return (
      alert.farmId === readAlert.farmId &&
      alert.houseId === readAlert.houseId &&
      alert.sensorType === readAlert.sensorType &&
      alert.sensorIndex === readAlert.sensorIndex &&
      alert.start === readAlert.start
    );
  }

  async openMenuOptions() {
    const cancelLabel = this.translateService.instant('CANCEL');
    const cancelOption = {
      text: cancelLabel,
      role: 'cancel',
    };
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'mortality-and-culls',
      buttons: [...(await this.menuOptions), cancelOption],
    });
    await actionSheet.present();
  }

  private async buildMenuOptions() {
    this.menuOptions = [];
    if (this.showDepletionOption) {
      this.menuOptions.push({
        cssClass: 'mortality-and-culls-depletion-action-button',
        text: this.translateService.instant(
          'FARM_DETAIL_PAGE.SEGMENTS.DEPLETIONS.DEPLETION_OPTION',
        ),
        handler: () => {
          this.openDepletionModal();
        },
      });
    }

    if (this.showAminoMobileLink) {
      this.menuOptions.push({
        label: 'FARM_DETAIL_PAGE.SEGMENTS.OPEN_FARM_INFORMATION',
        cssClass: 'mortality-and-culls-amino-action-button',
        text: this.translateService.instant(
          'FARM_DETAIL_PAGE.SEGMENTS.OPEN_FARM_INFORMATION',
        ),

        handler: () => {
          this.openFarmInAminoMobile();
        },
      });
    }
  }

  private async openDepletionModal() {
    const modal = await this.modalController.create({
      component: DepletionModalComponent,
      componentProps: {
        farmId: this.farmId,
        farmName: this.farm.farmName,
        farmNo: this.farm.farmNo,
        farmType: this.houseDetails[0].farmType,
      },
    });
    await modal.present();
  }

  private openFarmInAminoMobile() {
    let app: string;
    if (this.platform.is('android')) {
      app = 'com.mtech.amino.mobile';
    } else {
      Browser.open({
        url: `https://amino-mobile.mtech-systems.com/farms/${this.farm.proteinFarmsIRN}`,
        windowName: '_system',
      });
      return;
    }
    AppLauncher.canOpenUrl({ url: app }).then(
      (result) => {
        if (result.value) {
          Browser.open({
            url: `app://com.mtech.amino.mobile/farms/${this.farm.proteinFarmsIRN}`,
            windowName: '_system',
          });
        }
      },
      async (error): Promise<void> => {
        const toast = await this.toastController.create({
          duration: 5000,
          message: this.translateService.instant(
            'FARM_DETAIL_PAGE.SEGMENTS.UNABLE_LAUNCH_APP',
          ),
          position: 'top',
        });
        await toast.present();
      },
    );
  }

  async loadMoreEvents() {
    this.loaded = false;
    this.eventBatchNumber++;

    this.hasMoreEvents = await lastValueFrom(
      this.actualEventsDataService.getValue<boolean>('HasMoreEvents', {
        farmId: this.farmId,
        batchNumber: this.eventBatchNumber,
      }),
    );
    let newEvents = await lastValueFrom(
      this.actualEventsDataService.getEntities<FlockEvent>({
        farmId: this.farmId,
        batchNumber: this.eventBatchNumber,
      }),
    );
    newEvents.forEach((e) => {
      e.formattedTimestamp = moment(e.timestamp).format('lll');
      e.eventColor = FlockEventType.toColor(e.eventType);
      e.eventName = FlockEventType.getEventName(e.eventType);
      e.feedTransCodeLabel = FlockEventType.getFeedTransCodeLabel(e.eventType);
    });
    if (newEvents.length > 0) {
      newEvents = this.pastEvents.concat(newEvents);
      this.pastEvents = _.orderBy(
        newEvents,
        ['timestamp', 'refNo', 'eventType'],
        ['desc', 'asc', 'asc'],
      );
    }
    this.loadingEvents = setTimeout(() => (this.loaded = true), 2000);
  }

  async openMenuOptionsEvents() {
    const cancelLabel = this.translateService.instant('CANCEL');
    const cancelOption = {
      text: cancelLabel,
      role: 'cancel',
    };
    const menuOptions = [
      {
        cssClass: 'calendar-events',
        text: this.translateService.instant('CALENDAR'),
        handler: () => {
          this.openCalendarDialog();
        },
      },
      {
        cssClass: 'filters-events',
        text: this.translateService.instant('FILTERS'),
        handler: () => {
          this.openFiltersModal();
        },
      },
    ];
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'events-options',
      buttons: [...menuOptions, cancelOption],
    });
    await actionSheet.present();
  }

  openCalendarDialog() {
    const dialog = this.dialog.open(CalendarEventDialogComponent, {
      width: '304px',
      panelClass: 'calendar-dialog',
    });

    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.eventTimeline.scrollToDate(result);
    });
  }

  async openFiltersModal() {
    const modal = await this.modalController.create({
      component: EventFilterModalComponent,
      cssClass: 'filter-modal',
      componentProps: { eventFilters: this.eventFilters },
    });
    modal.onDidDismiss().then(async (data) => {
      const result = data?.data;
      if (!result) {
        return;
      }
      this.eventFilters = result;

      await this.userSettingsService.save('eventFilters', this.eventFilters);
    });
    await modal.present();
  }

  async onSelectEvent(event: FlockEvent) {
    const eventDetails = {
      ...event,
      kpi: event?.kpi,
    };
    const modal = await this.modalController.create({
      component: EventModalComponent,
      componentProps: {
        event: eventDetails,
      },
      cssClass: 'select-modal',
    });
    await modal.present();
  }
}
